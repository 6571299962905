















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Pagination from "@/components/paginaiton.vue";
import { getUnhandledDetail } from "@/request/yichang";
@Component({
  components: {
    Pagination,
  },
})
export default class Name extends Vue {
  @Prop()
  private patient_id: any;
  private data: any = [];
  private filter: any = {
    current_page: 1,
    page_count: 10,
    totalCount: 0,
  };
  @Watch("patient_id", { immediate: true })
  private patient_idChange(id: any) {
    if (id) {
      this.refreshData();
    }
  }
  private get user() {
    return this.$store.state.user;
  }
  private refreshData() {
    this.filter.current_page = 1;
    this.getList();
  }
  /**
   * @description 处理页码变化
   */
  private handleCurrentPageChange(val: any) {
    this.filter.current_page = val;
    this.getList();
  }
  private getList() {
    const params: any = {
      current_page: this.filter.current_page,
      page_count: 10,
      patient_id: this.patient_id,
    };
    getUnhandledDetail(this, params).then((res: any) => {
      this.data = res.data;
      this.filter.totalCount = res.total_count;
      this.filter.totalPage = res.total_page;
    });
  }
}
