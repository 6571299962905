






























































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Pagination from "@/components/paginaiton.vue";
import echarts from "echarts";
import { YichangEcharts, getZhibiaoList } from "@/request/yichang";
@Component({
  components: {
    Pagination,
  },
})
export default class Name extends Vue {
  @Prop()
  private patient_id: any;
  private part: any = "血压";
  private time: any = "7天";
  private list: any = [];
  private filter: any = {
    current_page: 1,
    page_count: 10,
    totalCount: 0,
  };
  @Watch("patient_id", { immediate: true })
  private patient_idChange(id: any) {
    if (id) {
      this.update();
    }
  }
  private get user() {
    return this.$store.state.user;
  }
  /**
   * @description 处理页码变化
   */
  private handleCurrentPageChange(val: any) {
    this.filter.current_page = val;
    this.getList();
  }
  private changeTab(type: any) {
    this.part = type;
    this.filter.current_page = 1;
    this.update();
  }
  private update() {
    this.getData();
    this.getList();
  }
  private getData() {
    const params = {
      patient_id: this.patient_id,
      doctor_id: this.user.doctor_id,
      type: this.part,
      时间: this.time,
      source: "pc医生",
    };
    YichangEcharts(this, params).then((res: any) => {
      const data = res;
      data.series.forEach((ele: any) => {
        ele.type = "line";
      });
      const Chart: any = echarts.init(this.$refs.line as HTMLCanvasElement);
      const option: any = {
        color: [
          "#FF9668",
          "#8FC31F",
          "#F35833",
          "#00CCFF",
          "#FFCC01",
          "#A74FAF",
          "#4F6BED",
          "#5DE7CB",
          "#ED4F6E",
        ],
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "3%",
          left: "3%",
          right: "3%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: data.category,
        },
        yAxis: {
          type: "value",
        },
        series: data.series,
        // series: [
        //   {
        //     name: "Email",
        //     type: "line",
        //     stack: "Total",
        //     data: [120, 132, 101, 134, 90, 230, 210],
        //   },
        //   {
        //     name: "Union Ads",
        //     type: "line",
        //     stack: "Total",
        //     data: [220, 182, 191, 234, 290, 330, 310],
        //   },
        //   {
        //     name: "Video Ads",
        //     type: "line",
        //     stack: "Total",
        //     data: [150, 232, 201, 154, 190, 330, 410],
        //   },
        //   {
        //     name: "Direct",
        //     type: "line",
        //     stack: "Total",
        //     data: [320, 332, 301, 334, 390, 330, 320],
        //   },
        //   {
        //     name: "Search Engine",
        //     type: "line",
        //     stack: "Total",
        //     data: [820, 932, 901, 934, 1290, 1330, 1320],
        //   },
        // ],
      };
      Chart.setOption(option, true);
    });
  }
  private getList() {
    const params: any = {
      patient_id: this.patient_id,
      type: this.part,
      current_page: this.filter.current_page,
      page_count: this.filter.page_count,
      source: "pc医生",
    };
    getZhibiaoList(this, params).then((res: any) => {
      this.list = res.warnings;
      this.filter.totalCount = res.total_count;
    });
  }
}
