




























































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import {
  YichangTongji,
  YichangList,
  getUnhandledList,
} from "@/request/yichang";
import { patientDetail } from "@/request/doctor";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
import Yichangchuli from "../../components/yichangchuli.vue";
import historyDetail from "../../components/history-detail.vue";
import Qushi from "../../components/qushi.vue";
import Pagination from "@/components/paginaiton.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    Pagination,
    Yichangchuli,
    historyDetail,
    Qushi,
  },
})
export default class Name extends mixins(listMixin) {
  private unhandledId: any = "";
  private cData: any = "";
  private msg: any = {};
  private data: any = [];
  private totalCount: any = null;
  private totalPage: any = 0;
  private window: any = window;
  private users: any[] = [];
  private jigous: any[] = [];
  private H: any = null;
  private ifChuli: boolean = false;
  private ifQushi: boolean = false;
  private ifDetail: boolean = false;
  private topTab: any = [
    {
      name: "血糖",
      label: "血糖",
    },
    {
      name: "血压",
      label: "血压",
    },
    {
      name: "心率",
      label: "心率",
    },
    {
      name: "体重",
      label: "体重",
    },
    {
      name: "血氧饱和度",
      label: "血氧",
    },

    {
      name: "体温",
      label: "体温",
    },
  ];
  private get localFilter() {
    if (!localStorage.getItem("yichangListFilter")) {
      return {};
    } else {
      return JSON.parse(localStorage.getItem("yichangListFilter") || "");
    }
  }
  private set localFilter(val) {
    if (!val) {
      return;
    }
    localStorage.setItem("yichangListFilter", JSON.stringify(val));
  }
  private get user() {
    return this.$store.state.user;
  }
  // 查看历史未处理详情
  private goDetail(item: any) {
    this.unhandledId = item.patient_id;
    this.ifDetail = true;
  }
  private openChuli(item: any) {
    this.cData = JSON.parse(JSON.stringify(item));
    this.$store.commit("updatePatient", item);
    this.ifChuli = true;
  }
  private closeChuli() {
    this.getList();
    this.ifChuli = false;
  }
  private openQushi(item: any) {
    this.cData = item;
    this.ifQushi = true;
  }
  private goPatient(item: any) {
    const params = {
      patient_id: item.patient_id,
      source: "pc医生",
    };
    patientDetail(this, params).then((res: any) => {
      this.$store.commit("updatePatient", res);
      this.$router.push("/main/workstation/patient-file");
    });
  }
  private send() {
    this.$confirm("该患者目前不在签约中，是否继续发送消息？")
      .then(() => {})
      .catch((e) => {
        console.log("取消了");
      });
  }
  getList() {
    const params = {
      search: this.filters.search,
      current_page: this.filters.current_page,
      page_count: this.filters.page_count,
      状态: this.filters["状态"],
    };
    YichangList(this, params).then((res: any) => {
      this.data = res.patients;
      this.filters.total_count = res.total_count;
      this.filters.total_page = res.total_page;
      if (this.data.length === 0) {
        this.ifShowEmpty = true;
      } else {
        this.ifShowEmpty = false;
      }
    });
  }
  private getData() {
    YichangTongji(this).then((res: any) => {
      this.msg = res;
    });
  }
  mounted() {
    if (localStorage.getItem("yichangListFilter")) {
      this.filters = JSON.parse(
        localStorage.getItem("yichangListFilter") as any
      );
    }
    this.getData();
    this.getList();
  }
}
